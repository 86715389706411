<template>
  <div>
    <fieldset>
        <legend>Formas de contacto</legend>
        <div v-for="(contacto,index) in contactos" :key="contacto.id" class="row form-group">
			<div class="col-sm-2">
                <label :for="'contacto_tipo_'+(index+1)" class="col-form-label col-sm-2">Tipo</label>
                <select v-model="contacto.tipo" :name="'contacto_tipo_'+(index+1)" :id="'contacto_tipo_'+(index+1)" class="form-control">
                    <option v-for="tipo_contacto in metodo_contacto" :key="tipo_contacto.id" :value="tipo_contacto.valor">{{ tipo_contacto.nombre }}</option>
                </select>
            </div>    
            <div class="col-sm-6">
                <label :for="'contacto_valor_'+(index+1)" class="col-form-label col-sm-2">Valor</label>
                <div class="input-group mb-2">
                    <div v-if="contacto.tipo != 'email'" class="input-group-prepend">
                        <div class="input-group-text">+52</div>
                    </div>
                    <input v-model="contacto.valor" type="text" class="form-control"  :name="'contacto_valor_'+(index+1)" :id="'contacto_valor_'+(index+1)" >
                </div>
            </div>   
            <div class="col-sm-2 pt-4">
                <Radio v-model="contacto.principal" name="contacto_preferida" :id="'contacto_preferida_'+(index+1)" value="1" label="Forma de contacto preferida" />
            </div>
            <div class="col-sm-2 d-flex justify-content-center">
                <div class="text-center mr-4">
                    <button class="btn secondary-btn btn-icon" title="Guardar contacto" @click="guardar_cambios(contacto)">
                        <span class="icon" title="Guardar cambios"><i class="gg-check"></i></span>
                    </button>
                </div>
                <div class="text-center">
                    <button class="btn complementary-btn btn-icon" title="Eliminar contacto" @click="eliminar_contacto(contacto)">
                        <span class="icon icon-trash" title="Eliminar contacto"><i class="gg-trash-empty"></i></span>
                    </button>
                </div>
            </div>                                         
        </div>
        <div class="row form-group">
            <div class="col-sm-3 offset-sm-9">
                <button class="btn btn-success btn-block" @click="modal_agregar_contacto=true">Agregar método de contacto</button>
            </div>
        </div>
    </fieldset>



        <AgregarContacto v-if="modal_agregar_contacto==true" @close="cerrar_agregar_contacto" :metodo_contacto="metodo_contacto" :ticket="ticket"/>
  </div>
</template>

<script>

import AgregarContacto from "./AgregarContacto";
import apiClientes from '@/apps/clientes/api/clientes';
import Radio from '@/components/Form/Radio';


export default {
    components:{
        AgregarContacto, Radio,
    },
    props:{
        metodo_contacto:{
            type:Array,
            required: true,
        },
        ticket:{
            type:Object,
            required:true,
        }        
    },
    data(){
        return{
            contactos:[],
            modal_agregar_contacto:false,
            contacto:{
                tipo:null,
                valor:null,
                principal:null,
            }
        }
    },
    methods:{
        cerrar_agregar_contacto(){
            this.modal_agregar_contacto = false;
            this.obtener_contactos();

        },
        async obtener_contactos(){
            this.$log.info('id del cliente para obtener contactos', this.ticket.cliente_id);
            try {
                this.contactos = (await apiClientes.listar_contactos_tickets(this.ticket.cliente_id)).data;
                this.$log.info('formas de contacto obtenidas para el cliente', this.contactos);                 
            } catch (e) {
                this.$log.info('err',e);
                this.$helper.showAxiosError(e,'Error');
            }
        },
        async guardar_cambios(contacto){
            try {
            // this.$log.info('guardar_contacto', contacto);
                await apiClientes.actualizar_contacto_tickets(contacto.cliente_id, contacto.id, contacto);
                this.obtener_contactos();

            // this.$log.info('respuesta modificar', data);
                this.$helper.showMessage('Contacto guardado','Los datos del contacto se guardaron correctamente');

            } catch (e) {
                this.$log.info('err',e);
                this.$helper.showAxiosError(e,'Error');
            }
        },
        async eliminar_contacto(contacto){

            if (this.contactos.length == 1)
                return this.$helper.showMessage('Error','Debes dejar al menos un método de contacto','error','alert');

            if (contacto.principal)
                return this.$helper.showMessage('Error','No puedes eliminar un método de contacto preferido','error','alert');            
           
            try {
                // this.$log.info(contacto);
                await apiClientes.eliminar_contacto_tickets(contacto.cliente_id, contacto.id);
                this.obtener_contactos();
                // this.$log.info('se elimino el contacto', data);
            } catch (e) {
                this.$log.info('err',e);
                this.$helper.showAxiosError(e,'Error');                
            }

        }

    },
    mounted(){
        this.obtener_contactos();
    }



    
}
</script>

<style>

</style>